// eslint-disable-next-line import/prefer-default-export
export const gatedSections = {
  featureBlocks: [
    {
      blockTitle: "What you get",
      blockSubTitle:
        "Get your copy of our whitepaper on deepening customer relationships with a loyalty program and get the insider tips you need to:",
      blockList: [
        "Boost average ticket price for repeat customers with a loyalty program",
        "Foster your restaurant's ability to create hassle-free experiences ",
        "Retain customers and build long-lasting relationships",
        "Enhance your relationship with your diners",
        "Boost your bottom line",
      ],
      blockImg: "gated-content-v3.png",
      forceReverse: false,
    },
    {
      blockTitle: "6 steps to strengthening guest relationships",
      blockSubTitle:
        "Get the detailed information you need to ride the loyalty wave at your restaurant. Our whitepaper lays the foundation with 6 straightforward steps that will get you on the path to improved guest retention and higher revenue.",
      blockImg: "gated-v4-a.png",
      forceReverse: false,
    },
  ],
};

export const gatedSectionsRestaurantDive = {
  featureBlocks: [
    {
      blockTitle: "In this whitepaper, you’ll learn",
      blockSubTitle: "",
      blockList: [
        "How to reimagine your employee’s experience in order to retain them",
        "5 popular tech solutions to circumvent the challenges caused by the labor shortage",
        "The benefits of using insights to make better business decisions",
      ],
      blockImg: "gated-content-restaurant-dive-1.png",
      forceReverse: false,
    },
    {
      blockTitle: "Reimagine your employee experience",
      blockSubTitle:
        "Hillary Holmes, General Manager of Von Elrod’s Beer Hall & Kitchen in Nashville, Tennessee reported that when her servers began using handhelds, they saw a 2% increase in average server tip percentages, a three-minute improvement on average check time, and a 40% reduction in headcount needs. Our whitepaper gives you insight into the tech solutions that will help you retain employees by improving efficiency and helping them earn more.",
      blockImg: "gated-content-restaurant-dive-2.png",
      forceReverse: false,
    },
  ],
};

export const gatedSectionsv1 = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Get the quick tips and advice you need in our easy-to-read infographic to maximize your restaurant operations now and in the future—no matter what the world throws at you next. Learn how to:",
      blockList: [
        "Boost operation efficiency",
        "Better train your restaurant staff",
        "Connect with your guests to drive repeat business",
        "Take advantage of new tech to improve margins",
      ],
      blockImg: "gated-v1-a.png",
      forceReverse: false,
    },
  ],
};

export const gatedSectionsv2 = {
  featureBlocks: [
    {
      blockTitle: "What you get",
      blockSubTitle:
        "Download our free whitepaper on building customer relationships with a well defined brand and learn how to:",
      blockList: [
        "Evolve your brand and digital presence",
        "Succeed and generate customer loyalty",
        "Attract more guests with your digital presence",
        "Stand out from your competitors",
        "Maintain consistent messaging and a cohesive voice",
      ],
      blockImg: "gated-v6-a.png",
      forceReverse: false,
    },
    {
      blockTitle: "Your brand is your business’s most valuable asset",
      blockSubTitle:
        "Learn how to take control of your business’s brand and leverage it to connect with customers and grow revenue. Our free whitepaper will walk you through every step, from assessing your customers to building a brand and maintaining it for prolonged success.",
      blockImg: "gated-v6-b.png",
      forceReverse: false,
    },
  ],
};

export const gatedSectionsv3 = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Download our restaurant infographic and learn the 10 questions you need to answer to determine if you have the right technology in your restaurant.",
      blockList: [
        "Front-of-house features for a great guest experience",
        "Flexibility with online and dine-in menus",
        "Real-time reporting to make smarter decisions",
      ],
      blockImg: "gated-v2-a.png",
      forceReverse: false,
    },
  ],
};

export const gatedSectionsv5 = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Download our free whitepaper on elevating your restaurant experience and strengthening your brand and get expert insights on how to:",
      blockList: [
        "Better understand your guests’ changing expectations",
        "Directly address your guests’ needs to encourage visits",
        "Streamline your guest interactions through digital tools",
        "Grow and evolve your brand",
        "Use technology to your advantage",
      ],
      blockImg: "gated-v5-a.png",
      forceReverse: false,
    },
  ],
};

export const gatedSectionsv6 = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Download our whitepaper and learn how new self-service and point-of-sale technology like kiosks can help you:",
      blockList: [
        "Create a better guest experience",
        "Speed up the ordering and fulfillment process",
        "Better manage and retain staff, even short-handed",
        "Increase average check size by as much as 20%",
        "Reduce errors and waste",
      ],
      blockImg: "gated-mp-a.png",
      // blockImg: "gated-v5-a.png",
      forceReverse: false,
      // ctaPrime: {
      //   ctaTitle: "Download",
      //   ctaTarget: "/SpotOn_Self_Service.pdf",
      // },
    },
  ],
};

export const howRetailersCanShipNational = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Download our whitepaper on how local retailers can offer nationwide shipping and learn how you can:",
      blockList: [
        "Update stock from any device",
        "Keep products from going out of stock",
        "Show a single catalog on every channel",
        "Compete with Amazon on low-cost shipping",
      ],
      blockImg: "local-national-a.png",
      // blockImg: "gated-v5-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Download",
        ctaTarget: "#how-retailers",
      },
    },
  ],
};
export const owningGuestData = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Download our whitepaper on new cloud-based restaurant data and see how information can help you:",
      blockList: [
        "Discover the dishes your guests love most",
        "Upsell more often and increase ticket sizes",
        "Stop throwing away spoiled food in the kitchen",
        "Find out which menu items aren’t making money",
        "Reward your top-performing servers and support staff",
      ],
      blockImg: "owning-data-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Download",
        ctaTarget: "#oaugd",
      },
    },
  ],
};

export const restaurantRetailPlanning = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "When you download our holiday planning guide, you’ll learn how to:",
      blockList: [
        "Offer promotions using holiday-specific data",
        "Boost sales with physical and virtual gift cards",
        "Accommodate more guests through reservations",
        "Turn your website into a seasonal selling machine",
        "Streamline your online ordering or checkout process",
      ],
      blockImg: "restaurant-retail-planning-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Download",
        ctaTarget: "#restuarant-retail-planing",
      },
    },
  ],
};

export const gatedSectionContactless = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "After reading our contactless technology survey, you’ll understand:",
      blockList: [
        "The top ordering trends in restaurants today",
        "What payment methods customers prefer most",
        "The current trends behind stadium ordering technology",
        "How contactless payments affect holiday shopping",
        "The most important factors of a brand loyalty program",
      ],
      blockImg: "contactless-technology-survey-a.png",
      forceReverse: false,
      // ctaPrime: {
      //   ctaTitle: "Download",
      //   ctaTarget: "#modern",
      // },
    },
  ],
};

export const gatedSectionStadiums = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "When you download this free white paper on self-service technology for stadiums and restaurants, you’ll discover:",
      blockList: [
        "How Urban Air Adventures Parks reached its highest revenue ever",
        "The 5 steps for implementing a loyalty rewards program",
        "How LSU saw a 25% increase in items per check",
        "What technology can eliminate physical menus completely",
        "How Live Nation reopened all their venues with record turnout",
      ],
      blockImg: "how-leading-stadiums-a.png",
      forceReverse: false,
      // ctaPrime: {
      //   ctaTitle: "Download",
      //   ctaTarget: "#stadiums",
      // },
    },
  ],
};

export const selfServePos = {
  featureBlocks: [
    {
      blockTitle: "What you get",
      blockSubTitle:
        "Download this free whitepaper on using technology to battle the restaurant worker shortage and discover how the right tech can help you:",
      blockList: [
        "Give guests control of their experience",
        "Make the most of every table touch",
        "Serve more guests with less staff",
        "Schedule staff within your budget",
        "Avoid out-of-control labor costs",
      ],
      blockImg: "gated-self-serve-pos.png",
      forceReverse: false,
    },
    {
      blockTitle: "Thrive on less staff with more technology",
      blockSubTitle:
        "Discover the latest labor trends within the restaurant industry, and see what operators are doing to combat staffing challenges. Our free whitepaper analyzes how managers retain their limited staff and the technology they’re using to make up the difference.",
      blockImg: "gated-self-serve-pos1.png",
      forceReverse: false,
    },
  ],
};

export const newRestaurantPoP = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Get your free copy of SpotOn’s playbook for new restaurants and gain the insights you need to set your restaurant business on the path to profitability right from the start.",
      blockList: [
        "Attract customers online with low-cost online tools",
        "Manage & retain employees in a competitive labor market",
        "Create streamlined in-house workflows",
        "Generate in-store and online revenue streams",
        "Get real-time data to monitor and adapt your business",
        "Build a rock-solid reputation",
      ],
      blockImg: "new-restaurant-pop-1.png",
      forceReverse: false,
    },
  ],
};

export const saveTimeOnLaborManagement = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Get your free copy of our SpotOn Teamwork playbook and learn how a labor management tool that works in sync with your POS can save you time, money, and hassle.",
      blockList: [
        "Learn how a labor management tool can increase efficiency",
        "Easily implement procedures to comply with labor wage laws",
        "See how streamlining employee communication can help you retain staff",
        "Create optimized shifts with sales and labor forecasting",
        "Discover how managing staff across multiple locations doesn't need to be complicated",
      ],
      blockImg: "save-time-on-labor-management-1.png",
      forceReverse: false,
    },
  ],
};

export const driveOperationalImprovements = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Download your complimentary copy of our SpotOn online review management playbook and learn how online reviews left on sites like Yelp, Tripadvisor, and Google can be used to drive operational efficiencies in your restaurant.",
      blockList: [
        "Dive into why managing your online reputation matters",
        "Learn how to save time by monitoring your reviews from one dashboard",
        "Find out how to claim your pages on popular review sites",
        "Uncover the benefits of staying on top of your online reputation",
        "Learn how to identify trends in customer feedback and what to do about it",
        "Discover how and when to respond to customer feedback",
      ],
      blockImg: "drive-operational-improvements-a.png",
      forceReverse: false,
    },
  ],
};

export const spotonCapital = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Get your free copy of our SpotOn Capital playbook and discover a better way to solve your short-term cash flow gaps so your restaurant can move forward.",
      blockList: [
        "Learn about different lending options for restaurants",
        "Uncover what type of loans you should stay away from",
        "Get ideas on how to make your restaurant profitable with funding",
        "Gain access to the cash you need in just a couple of days",
        "Discover a repayment program that works at your restaurant's pace",
        "Get a functional step-by-step plan for funding your restaurant",
      ],
      blockImg: "gated-capital-1.png",
      forceReverse: false,
    },
  ],
};

export const startingAnOnlineStore = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Get the 7 Step Guide to Starting an Online Store for free and start your path to selling online, streamlining the shipping process, and reaching a whole new group of customers.",
      blockList: [
        "Get the secret to saving time and money with 1 solution for in-store and online sales",
        "See how selling online can boost revenue",
        "Learn how to build trust with your online customers",
        "Discover how a shipping integration can make fulfillment cheaper and easier",
        "Rethink the checkout process by allowing for all payment types",
        "Find marketing tools to leverage customer data",
      ],
      blockImg: "starting-an-online-store-a.png",
      forceReverse: false,
    },
  ],
};

export const drivingRestaurantEfficiency = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "Get the complete playbook and learn how to use intuitive data from a cloud-based point-of-sale to streamline operations and identify profit-boosting opportunities. You’ll get insider tips on how to:",
      blockList: [
        "Streamline scheduling and operating hours to reduce costs",
        "Optimize your menu for maximum profitability",
        "Identify hidden revenue opportunities",
        "Use the top 5 reports from successful restaurants",
        "Leverage software integrations to automate time-consuming tasks",
      ],
      blockImg: "driving-restaurant-efficiency-1.png",
      forceReverse: false,
    },
  ],
};

export const thePathToProfitForNewRestaurants = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you'll see",
      blockSubTitle:
        "In this webinar, hospitality veterans Hillary Holmes, Benson Wang, and Chip Klose offer their expertise to help you maximize profits. They provide key insights about:",
      blockList: [
        "Diversifying revenue with online ordering",
        "Comprehensive reporting",
        "Integrating loyalty with point-of-sale discounts & sales",
        "Streamlining operations & employee management",
        "Building a customer database",
        "Turning tables with less staff",
      ],
      blockImg: "the-path-to-profit-for-new-restaurants-1.png",
      forceReverse: false,
    },
  ],
};

export const webinarPointsOfProfit = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you get",
      blockSubTitle:
        "In this webinar, hospitality veterans Hillary Holmes, Troy Hooper, and Jason Berkowitz discuss how you can continue to grow your restaurant business despite setbacks like inflation. They provide key insights about:",
      blockList: [
        "Achieving higher revenue",
        "Using technology to diversify revenue streams",
        "Reducing operating costs and building customer loyalty",
        "Turning tables with less staff and cutting down third-party dependency",
      ],
      blockImg: "increase-points-of-profit-1.png",
      forceReverse: false,
    },
  ],
};

export const webinar2023StateOfTheRestaurant = {
  featureBlocks: [
    {
      hideDesktop: true,
      blockTitle: "What you'll see'",
      blockSubTitle:
        "In this webinar, SpotOn presents findings from the National Restaurant Association 2023 State of the Restaurant Industry report. Tune in as Hudson Riehle, Senior Vice President of Research & Knowledge, discusses findings that will shape the restaurant industry in 2023 and how operators should prepare including:",
      blockList: [
        "Achieving higher revenue and reducing costs",
        "Using technology to diversify revenue streams",
        "Building customer loyalty",
        "Driving employment growth",
      ],
      blockImg: "webinar-2023-state-of-the-restaurant-industry-1.png",
      forceReverse: false,
    },
  ],
};
