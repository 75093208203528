import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { spotonCapital } from "../../../data/landing/gated-v2";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedCTA = loadable(() =>
  import("../../../components/CustomGated/GatedCTA")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["firstname", "email", "phone", "company", "get_demo"];

const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv1 = () => {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Gated Assets | Alternative Financing for Restaurants"
        description="Download your free SpotOn Capital playbook and learn how you can easily access funding for your restaurant's next big project or solve for cash-flow shortages."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row
        className="gate-heading-v1"
        gutter={[32, 32]}
        align="middle"
        id="restuarant-retail-planing"
      >
        <Col lg={12}>
          <div id="oaugd">
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              Alternative financing for restaurants
            </Heading>
            <p className="gated-intro-para cta-light">
              Getting the capital you need to address cash-flow shortages or
              finance your next project has become increasingly more difficult.
              Learn how you can easily access funding tailored for restaurants
              with SpotOn Capital.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              btnText="Submit"
              formTitle="Download your free playbook"
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formItems={data}
              formDataToSend={formDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free download today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/alternative-financing-for-restaurants/thank-you"
              gatedCampaign="path-to-profit"
              lineOfBusiness={lineOfBusiness.HOSPITALITY}
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image className="banner-text" imageName="banner-text.png" />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial sectionData={spotonCapital} />
        </Col>

        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="Take your restaurant to its next milestone with fast and simple funding"
            mark="."
            subtext="Oftentimes, the only thing standing between you and a profitable restaurant is money. With a lending partner that understands the goals of your restaurant, you can gain access to the capital you need and realize your restaurant's full potential."
          />
        </Col>
      </Row>

      <Row
        gutter={[32, 32]}
        className="mt-10 lg:mt-20 mb-20 lg:mb-32 px-4 lg:px-0"
      >
        <Col xs={24}>
          <Image className="min-w-full" imageName="gated-capital-2.png" />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv1;
